import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

import Video from "../components/modules/Video.js/index.js";
import CardSection from "../components/modules/CardSection";

const IndexPage = () => (
  <Layout display="hidden">
    <Seo title="Inicio" />
    <div className="absolute top-0 right-0 bottom-0  h-screen left-0 z-30">
      <div className="absolute top-0 z-10 lg:hidden overlay w-full">
        <StaticImage
          height={743}
          src="../images/Home/MobileHome.jpg"
          placeholder="tracedSVG"
          alt="hero image"
          className="min-h-screen w-full"
        />
      </div>
      <div className="absolute top-0 z-10 hidden lg:block w-full">
        <StaticImage
          src="../images/Home/hero.jpg"
          placeholder="tracedSVG"
          alt="hero image"
          className="max-h-screen z-0 w-full"
        />
      </div>
      <div className="block h-screen" />
      <div className="mt-6 mb-6 pb-6">
        <Video />
        <CardSection />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
