import { Link } from "gatsby";
import React from "react";
import HomeVideo from "../../../../assets/HomeVideo.mp4";

const Video = () => {
  return (
    <div className="grid lg:grid-cols-2 place-items-center gap-8 lg:gap-4 mx-auto max-w-6xl">
      <video
        width={510}
        src={HomeVideo}
        muted
        autoPlay
        loop
        type="video/mp4"
        className="shadow-sm mb-auto my-auto mx-auto lg:mr-1"
      />
      <div className="flex flex-col items-center gap-8 pt-4 pb-8 lg:pb-0 lg:pt-0 max-w-sm mx-auto lg:max-w-md lg:pr-6 mr-auto">
        <p className="paragraph text-center">
          Estamos en Barra de Nexpa, un paraíso de la Costa Michoacana y te
          esperamos con todo lo que necesitas para disfrutar de unas increíbles
          vacaciones.
        </p>
        <Link to="/card" className="pb-2">
          <button className="button-primary">Conoce nuestras cabañas</button>
        </Link>
      </div>
    </div>
  );
};

export default Video;
