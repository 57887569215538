import React from "react";
import {
  CardActivitiesHome,
  CardCabinHome,
  CardRestaurantHome,
} from "../../Cards";

const CardSection = () => {
  return (
    <div className="grid mt-4 lg:mt-6">
      <div className="grid lg:grid-cols-2 mx-auto gap-6">
        <CardActivitiesHome />
        <div className="grid lg:grid-rows-2 gap-4 place-items-center">
          <CardCabinHome />
          <CardRestaurantHome />
        </div>
      </div>
    </div>
  );
};

export default CardSection;
